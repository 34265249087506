export default {
    home: {
        home1: '何文田太子道西296号',
        home2: '物业亮点',
        home3: '45户全新豪华大宅的建筑，配备高楼底和全落地玻璃，并提供几乎1：1的车位比例(43个住户车位及2个访客车位)。住宅享有开阔的景观，前临翠绿美景，远眺狮子山。此外，罕见的豪华会所设有21米长的园林泳池，健身房，桑拿浴空和休息区域。这楝大楼位于九龙区传统豪宅区，周边名校林立。临近九龙城和旺角东站，日常生活所需一应俱全。',
        home4: '多个传统王牌名校',
        home5: '国际中小学的重镇区域',
        home6: 'Terra位于香港著名的校网，位处九龙区名校网34校网，区内传统名校为数众多，属非常受家长青睐的名校区。',
        home7: '至于中学校网属九龙城区，知名学府包括拔萃男书院、喇沙书院、玛利诺修院学校及英皇佐治五世学校等“争崩头”名校，校网优势无可比拟。',
        home8: '俯瞰山脉美景',
        home9: '视野尽收眼底',
        home10: '山景一览无遗 美不胜收',
        home11: '知名建筑师',
        home12: '及设计师团队精心打造',
        home13: '区内具地标性物业',
        home14: '房屋平面图',
        home15: '住宅',
        home16: '网内受欢迎的学校包括圣罗撒学校及协恩中学附属小学等；',
        home17: '供买方参考资料及免责条款',
        home18: '本网站www.terraluxuryhk.com 如被视为广告则本告示适用。',
        home19: '发展项目名称：太子道西296号发展项目（“发展项目”）的“隆敍”',
        home20: '备注：太子道西296号发展项目，建于九龙内地段2097号馀段（“该地段”,中文名称为“隆敍”，英文名称为 “TERRA”。',
        home21: '区域：何文田',
        home22: '隆敍所位于的街道名称及门牌号数：太子道西296号',
        home23: '卖方为施行《一手住宅物业销售条例》的互联网网站的网址：www.terraluxuryhk.com',
        home24: '本广告／宣传资料内载列的相片、图像、绘图或素描显示纯属画家对有关发展项目之想像。有关相片、图像、绘图或素描并非按照比例绘画及／或可能经过电脑修饰处理。准买家如欲了解发展项目的详情，请参阅售楼说明书。卖方亦建议准买家到有关发展地盘作实地考察，以对该发展地盘、其周边地区环境及附近的公共设施有较佳了解。',
        home25: '卖方︰志德投资有限公司',
        home26: '卖方（拥有人）的控权公司︰汇谦有限公司',
        home27: '发展项目的认可人士以其专业身分担任经营人、董事或僱员的商号或法团: 周余石（香港）有限公司',
        home28: '发展项目的认可人士 : 甘庆邦先生',
        home29: '发展项目的承建商 : 显利工程有限公司',
        home30: '就发展项目中的住宅物业的出售而代表拥有人行事的律师事务所 : 卢王徐律师事务所',
        home31: '已为发展项目的建造提供贷款或已承诺为该项建造提供融资的认可机构 : 中国银行（香港）有限公司（已于2023 年12 月5 日解除）',
        home32: '卖方保留权利不时改动建筑图则及其他图则，隆敍之设计以政府相关部门最后批准之图则为准。',
        home33: '本广告在拥有人的同意下由如此聘用的人发布。',
        home34: '卖方建议准买家参阅有关售楼说明书，以了解隆敍的资料。详情请参阅售楼说明书。',
        home35: '本广告／宣传资料仅供参考，并不构成亦不得被诠释成卖方就隆敍或其任何部份作出任何不论明示或隐含之合约条款、要约、陈述、承诺或保证。',
        home36: '最后更新日期︰{date}',
    },
    navigator: {
        navigator1: '售楼说明书',
        navigator2: '价单',
        navigator3: '成交纪录册',
        navigator4: '销售安排资料',
        navigator5: '公契',
        navigator6: '鸟瞰照片',
        navigator7: '招标文件',
    },
    footer: {
        footer1: '地址:何文田太子道西296号',
        footer2: '地段编号:九龙内地段第2097',
        footer3: '除特别注明外，本网页内之相片及图像并非于或自发展项目期数或其任何部分拍摄或以发展项目期数或其任何部分作依据制作，并非反映发展项目期数或其任何部分外观、景观、设计、装置、装修物料、设备、设施、服务和使用，亦可能与发展项目期数或其任何部分无关。相关相片及图像并不构成亦不得诠释成作出任何不论明示或隐含之要约、陈述、承诺或保证。',
    },
    language: {
        language: '简体中文',
        zh: 'zh-CN',
        en: 'en',
        tw: 'zh-TW',
        zhName: '简体中文',
        zhTwName: '繁體中文',
        enName: 'English',
    },
}