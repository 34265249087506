<template>
  <div class="navigation position">
    <div class="left">
      <img src="@/assets/img/title@2x.png" alt="">
      <ul>
        <li @click="dialogShow(item.text, item.index)" v-for="(item, index) in titleList" :key="index">{{ item.text }}</li>
      </ul>
    </div>
    <div class="right">
      <el-dropdown @command="handleSetLanguage">
  <span class="el-dropdown-link">
    <span class="language">{{ $t('language.language') }}</span><i class="el-icon-arrow-down el-icon--right"></i>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item
                  :disabled="language === $t('language.zh')"
                  command="zh-CN"
                  ><span>{{ $t('language.zhName') }}</span></el-dropdown-item>
                  <el-dropdown-item
                  :disabled="language === $t('language.tw')"
                  command="tw"
                  ><span>{{ $t('language.zhTwName') }}</span></el-dropdown-item>
    <el-dropdown-item
                  :disabled="language === $t('language.en')"
                  command="en"
                  ><span>{{ $t('language.enName') }}</span></el-dropdown-item
                >
              </el-dropdown-menu>
</el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['language']),
    titleList() {
      return [
        {
          text: this.$t('navigator.navigator1'),
          index: '100000'
        },
        {
          text: this.$t('navigator.navigator2'),
          index: '100001'
        },
        {
          text: this.$t('navigator.navigator3'),
          index: '100002'
        },
        {
          text: this.$t('navigator.navigator4'),
          index: '100003'
        },
        {
          text: this.$t('navigator.navigator5'),
          index: '100004'
        },
        {
          text: this.$t('navigator.navigator6'),
          index: '100005'
        },
        {
          text: this.$t('navigator.navigator7'),
          index: '100006'
        },
      ]
    }
  },
  created() {
  },
  methods: {
    ...mapMutations(['remTokenInfo', 'setDialogShow']),
    // 把选择的语言传入vuex
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.commit('setLanguage', lang)
    },
    dialogShow(text, index){
      this.setDialogShow({ show: true, title: text, index: index })
    }
  }
}
</script>

<style lang="less" scoped>
/* 添加媒体查询 */  
@media (min-width: 1301px) {  
  .position {  
    position: fixed;
    top: 0;
    z-index: 99;
  }  
}
.navigation {
  width: 100%;
  height: 0.72rem;
  background: transparent;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 0.4rem;
  min-width: 1300px;
  .left {
    font-weight: bold;
font-size: .14rem;
color: #7C6B56;
line-height: .24rem;
text-align: left;
font-style: normal;
text-transform: none;
display: flex;
align-items: center;
img {
  width: 1.63rem;
  height: .39rem;
  margin-right: .03rem;
}
ul {
  display: flex;
  li {
    margin-left: .42rem;
  }
  li:hover {
    color: #E4B57E;
  }
}
  }
  /deep/ .right {
    cursor: pointer;
    .el-dropdown {
      color: #fff;
      font-size: .14rem;
    font-weight: 400;
    }
  }
}
</style>
