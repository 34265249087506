<template>
  <div class="home">
    <navigation-bar></navigation-bar>
    <div class="box1">
      <img src="@/assets/img/title@2x.png" alt=""/>
      <div>{{ $t('home.home1') }}</div>
    </div>
    <div class="box2">
      <div class="left">
        <h1>{{ $t('home.home2') }}</h1>
        <div>
          {{ $t('home.home3') }}
        </div>
      </div>
      <img src="@/assets/img/image 15@2x.png" alt=""/>
    </div>
    <div class="box2">
      <img src="@/assets/img/image 16@2x.png" alt=""/>
      <div class="left" style="background: #7c6b56;">
        <h1 class="title">
          {{ $t('home.home4') }}<br/>
          {{ $t('home.home5') }}
        </h1>
        <div class="content">
          {{ $t('home.home6') }}
          {{ $t('home.home16') }}
          {{ $t('home.home7') }}
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="left">
        <div class="item">
          {{ $t('home.home8') }}<br/>
          {{ $t('home.home9') }}<br/>
          {{ $t('home.home10') }}
          <div class="hint"></div>
        </div>
      </div>
      <div class="right">
        <div class="item">
          {{ $t('home.home11') }}<br/>
          {{ $t('home.home12') }}<br/>
          {{ $t('home.home13') }}
          <div class="hint"></div>
        </div>
      </div>
    </div>
    <div class="box4">
      <div v-for="(v, index) in imgList" :key="index">
        <img
            :src="item.show ? item.colorImg : item.blackImg"
            alt=""
            v-for="(item, index) in v"
            :key="index"
            @mouseenter="mouseenter(item)"
            @mouseleave="mouseleave(item)"
        />
      </div>
    </div>
    <!-- <div class="box5">
      <h1>{{ $t('home.home14') }}</h1>
      <div
        class="content"
        @mouseenter="on_bot_enter"
        @mouseleave="on_bot_leave"
      >
        <swiper :options="swiperOption" ref="swiper">
          <swiper-slide v-for="(item, index) in swiperList" :key="index">
            <div class="swiper-item">
              <img :src="item.img" alt="" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="btn">
        <div class="button">
          <span>2/F- 6/F</span>
          <i>{{ $t('home.home15') }} Apartments</i>
        </div>
      </div>
    </div> -->
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogShow"
        width="30%"
        :before-close="handleClose"
    >
      <div class="item" v-for="(item, index) in filesList[dialogIndex]" :key="index">
        <div class="left">
          <img src="@/assets/img/Frame@2x.png" alt="">
          <div class="left-text">
            <h1>{{ item.document }}</h1>
            <div>{{ timestampToTime(item.createdTime) }}</div>
          </div>
        </div>
        <div class="right">
          <el-link @click="policyClick(item.document, item.url)">下載</el-link>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        class="created-dialog"
        :title="$t('home.home17')"
        :visible.sync="createdDialogVisible"
        width="30%">
      <p>{{ $t('home.home18') }}</p>
      <p>{{ $t('home.home19') }}</p>
      <p>{{ $t('home.home20') }}</p>
      <p>{{ $t('home.home21') }}</p>
      <p>{{ $t('home.home22') }}</p>
      <p>{{ $t('home.home23') }}</p>
      <p>{{ $t('home.home24') }}</p>
      <p>{{ $t('home.home25') }}</p>
      <p>{{ $t('home.home26') }}</p>
      <p>{{ $t('home.home27') }}</p>
      <p>{{ $t('home.home28') }}</p>
      <p>{{ $t('home.home29') }}</p>
      <p>{{ $t('home.home30') }}</p>
      <p>{{ $t('home.home31') }}</p>
      <p>{{ $t('home.home32') }}</p>
      <p>{{ $t('home.home33') }}</p>
      <p>{{ $t('home.home34') }}</p>
      <p>{{ $t('home.home35') }}</p>
      <p>{{ $t('home.home36',{date:getNow()}) }}</p>
    </el-dialog>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import NavigationBar from '@/components/navigation-bar.vue'
import FooterBar from '@/components/footer.vue'
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import {mapMutations, mapState} from 'vuex'
// import filesList from '@/file/anncmnt.json'
export default {
  name: 'HomeView',
  components: {
    NavigationBar,
    FooterBar,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      createdDialogVisible: true,
      swiperOption: {
        speed: 1000,
        slidesPerView: 1.7, // 设置slider容器能够同时显示的slides数量,可以是小数,设置为2时,如图所示，设置为3则会出现三张完整的active slide，如API的例子,即设置为偶数时会自动使两边的缩进，类似遮盖一半的效果
        spaceBetween: 0,
        centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左
        slideToClickedSlide: true, // true:点击slide会过渡到这个slide,默认false
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        autoplay: true,
        // effect: 'coverflow', // 类型卡片
        disableOnInteraction: false, // 用户交互时是否停止自动播放
        initialSlide: 0,
        coverflowEffect: {
          slideShadows: false, // 页面阴影效果
          rotate: 0, // 旋转的角度
          // stretch: 1300, // 拉伸 图片间左右的间距和密集度，越大靠得越近
          depth: 300, // 深度 切换图片间上下的间距和密集度，值越大z轴距离越远，看起来越小。
          modifier: 0.8, // 修正值 该值越大前面的效果越明显
        },
      },
      imgList: [
        [
          {
            blackImg: require('@/assets/img/black/Rectangle 3809.png'),
            colorImg: require('@/assets/img/color/Rectangle 3809.png'),
          },
          {
            blackImg: require('@/assets/img/black/Rectangle 3810.png'),
            colorImg: require('@/assets/img/color/Rectangle 3810.png'),
          },
          {
            blackImg: require('@/assets/img/black/Rectangle 3811.png'),
            colorImg: require('@/assets/img/color/Rectangle 3811.png'),
          },
          {
            blackImg: require('@/assets/img/black/Rectangle 3812.png'),
            colorImg: require('@/assets/img/color/Rectangle 3812.png'),
          },
        ],
        [
          {
            blackImg: require('@/assets/img/black/Rectangle 3813.png'),
            colorImg: require('@/assets/img/color/Rectangle 3813.png'),
          },
          {
            blackImg: require('@/assets/img/black/Rectangle 3814.png'),
            colorImg: require('@/assets/img/color/Rectangle 3814.png'),
          },
          {
            blackImg: require('@/assets/img/black/Rectangle 3815.png'),
            colorImg: require('@/assets/img/color/Rectangle 3815.png'),
          },
          {
            blackImg: require('@/assets/img/black/Rectangle 3816.png'),
            colorImg: require('@/assets/img/color/Rectangle 3816.png'),
          },
        ],
        [
          {
            blackImg: require('@/assets/img/black/Rectangle 3817.png'),
            colorImg: require('@/assets/img/color/Rectangle 3817.png'),
          },
          {
            blackImg: require('@/assets/img/black/Rectangle 3818.png'),
            colorImg: require('@/assets/img/color/Rectangle 3818.png'),
          },
          {
            blackImg: require('@/assets/img/black/Rectangle 3819.png'),
            colorImg: require('@/assets/img/color/Rectangle 3819.png'),
          },
          {
            blackImg: require('@/assets/img/black/Rectangle 3820.png'),
            colorImg: require('@/assets/img/color/Rectangle 3820.png'),
          },
        ],
      ],
      filesList: [],
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapState(['language', 'dialogShow', 'dialogTitle', 'dialogIndex']),
    swiperList() {
      return [
        {
          img: require(`@/assets/img/image 16.png`),
        },
        {
          img: require(`@/assets/img/image 17.png`),
        },
        {
          img: require(`@/assets/img/image 18.png`),
        },
      ]
    },
    swiper() {
      return this.$refs.swiper.swiper
    },
  },
  watch: {
    dialogShow(newVal, oldVal) {
      if (newVal) {
        this.getList()
      }
    }
  },
  methods: {
    ...mapMutations(['setDialogShow']),
    getList() {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          this.filesList = JSON.parse(xhr.responseText)
        }
      };
      xhr.open('GET', '/document/index.json', true); // 根据实际的 JSON 文件路径进行调整
      xhr.send();
    },
    handleClose(done) {
      this.setDialogShow({ show: false, title: '', index: '' })
    },
    mouseenter(item) {
      this.$set(item, 'show', true)
    },
    mouseleave(item) {
      this.$set(item, 'show', false)
    },
    // 鼠标移入
    on_bot_enter() {
      this.swiper.autoplay.stop()
    },
    // 鼠标移出
    on_bot_leave() {
      return this.swiper.autoplay.start()
    },
    policyClick(name, url) {
      const link = document.createElement('a')
      link.href = decodeURI(url)
      // return console.log('file/' + name + '.pdf')
      link.download = name
      link.target = '_blank'
      link.click()
    },

    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp * 1000);
      var Y = date.getFullYear();
      var M =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1);
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
      return M + '-' + D + '-' + Y;
    },
    getNow() {
      const date = new Date();
      if (this.$store.state.language === 'en') {
        return new Intl.DateTimeFormat("en-GB", { year: "numeric", month: "long", day: "numeric" }).format(date);
      } else {
        return new Intl.DateTimeFormat("zh-CN", { year: "numeric", month: "long", day: "numeric" }).format(date);
      }
    }
  },
}
</script>
<style lang="less" scoped>
.home {
  width: 100%;

  .box1 {
    width: 100%;
    height: 9.1rem;
    background: url('~@/assets/img/image 14@2x.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 3.48rem;
      height: 0.84rem;
    }

    div {
      font-family: PingFang HK, PingFang HK;
      font-weight: 100;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.39rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }

  .box2 {
    width: 100%;
    height: 8.21rem;
    display: flex;

    .left {
      width: 50%;
      height: 100%;
      box-sizing: border-box;
      padding: 0 1.1rem 0 1.1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        width: 100%;
        font-family: PingFang HK, PingFang HK;
        font-weight: 100;
        font-size: 0.55rem;
        color: #e4b57e;
        line-height: 0.66rem;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 0.56rem;
      }

      .title {
        width: 4.7rem;
        font-family: PingFang HK, PingFang HK;
        font-weight: 100;
        font-size: 0.47rem;
        color: #13161f;
        line-height: 0.56rem;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      div {
        font-family: PingFang HK, PingFang HK;
        font-weight: 300;
        font-size: 0.18rem;
        color: #e4b57e;
        line-height: 0.33rem;
        text-align: justified;
        font-style: normal;
        text-transform: none;
      }

      .content {
        width: 4.7rem;
        font-family: PingFang HK, PingFang HK;
        font-weight: 300;
        font-size: 0.18rem;
        color: #13161f;
        line-height: 0.33rem;
        text-align: justified;
        font-style: normal;
        text-transform: none;
      }
    }

    img {
      width: 50%;
      height: 100%;
    }
  }

  .box3 {
    width: 100%;
    height: 7.3rem;
    box-sizing: border-box;
    display: flex;
    padding: 1.45rem 1.54rem 2.74rem 1.54rem;
    justify-content: space-between;

    .left {
      width: 5.41rem;
      height: 3.14rem;
      background: url('~@/assets/img/Rectangle 3821@2x.png') no-repeat;
      background-size: 100% 100%;
      position: relative;
    }

    .right {
      width: 5.41rem;
      height: 3.14rem;
      background: url('~@/assets/img/Rectangle 3822@2x.png') no-repeat;
      background-size: 100% 100%;
      position: relative;
    }

    .item {
      width: 3.17rem;
      height: 1.62rem;
      background: #7c6b56;
      box-sizing: border-box;
      padding: 0.31rem 0.3rem 0.32rem 0.22rem;
      position: absolute;
      top: 2.81rem;
      left: 1.12rem;
      font-family: PingFang HK, PingFang HK;
      font-weight: 100;
      font-size: 0.16rem;
      color: #ffffff;
      line-height: 0.33rem;
      text-align: left;
      font-style: normal;
      text-transform: none;

      .hint {
        width: 0.2rem;
        height: 0.02rem;
        background: #ffffff;
        position: absolute;
        bottom: 0.1rem;
        right: 0.14rem;
      }
    }
  }

  .box4 {
    width: 100%;
    height: 5.85rem;

    img {
      width: 25%;
      height: 1.95rem;
      cursor: pointer;
    }
  }

  .box5 {
    padding: 0.97rem 0;

    h1 {
      font-family: PingFang HK, PingFang HK;
      font-weight: 100;
      font-size: 48px;
      color: #e4b57e;
      line-height: 58px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-bottom: 0.49rem;
    }

    .content {
      width: 100%;
      height: 3.82rem;
      box-sizing: border-box;
      margin-bottom: 0.58rem;

      .swiper-item {
        width: 8rem;
        height: 3.82rem;
        background: #2a2d36;
        border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
        border: 0.01rem solid #2b2b2b;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 7.53rem;
          height: 3.36rem;
        }
      }

      .swiper-container {
        width: 100% !important;
        height: 3.82rem !important;
      }

      // .swiper-scrollbar-drag {
      //   background: #beae79;
      // }
      .swiper-slide {
        // width: 10rem !important;
        // border-radius: .12rem !important;
        display: flex;
        justify-content: center;
      }

      .swiper-wrapper {
        height: 3.42rem !important;
      }
    }

    .btn {
      display: flex;
      justify-content: center;

      .button {
        width: 3.69rem;
        height: 0.76rem;
        background: #7c6b56;
        font-family: PingFang HK, PingFang HK;
        font-weight: 600;
        font-size: 0.22rem;
        color: #13161f;
        line-height: 0.26rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        i {
          font-weight: 300;
          font-size: 0.18rem;
          line-height: 0.22rem;
        }
      }

      .button:hover {
        color: #fff;
      }
    }
  }

  /deep/ .created-dialog {
    p {
      margin-bottom: .15rem;
    }

    .el-dialog__body {
      color: #000;
      padding-bottom: .6rem !important;
      // background-color: rgba(255, 255, 255, 0.9);
    }

    .el-dialog {
      margin-top: 5vh !important;
    }
  }
}
</style>
