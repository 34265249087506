import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/common/index.css'
import i18n from '@/lang/index'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import { Dropdown, DropdownMenu, DropdownItem, Dialog, Link } from 'element-ui'
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Dialog);
Vue.use(Link);
// 设置语言
// Vue.use(TableColumn);
Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  created(){
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
		setRemPc();
        window.addEventListener('resize', setRemPc);//浏览器窗口大小改变时调用rem换算方法
	},
  render: h => h(App)
}).$mount('#app')

//rem计算
function setRemPc() {
  var width = 100/1440;// 表示1510的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
  if(bodyWidth > 1280 && bodyWidth <= 1920) { // rem适配 屏幕缩放太大或太小会错乱 加个判断在多少尺寸之内才会变化
    var rem = bodyWidth * width;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
  } else {
    document.getElementsByTagName('html')[0].style.fontSize = 100 + 'px'; // 如果不在判断尺寸之内就设置为基础值, 这个基础值100对应第一个100
  }
}
