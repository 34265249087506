export default {
    home: {
        home1: '296 Prince Edward Road West, Ho Man Tin',
        home2: 'Property Highlights',
        home3: 'A building of 45 newly built luxurious residences, equipped with high ceilings and full-length glass windows, offering an almost 1:1 parking ratio (43 residential car part and 2 visitor car park). Residents enjoy expansive views, overlooking lush greenery and the Lion Rock in the distance. Additionally, the rare luxury clubhouse features a 21-meter long garden swimming pool, gym, sauna, and relaxation area. This building is located in Kowloon\'s traditional luxury residential area, surrounded by prestigious schools. Close to Kowloon City and Mong Kok East Station, it offers convenient access to daily necessities.',
        home4: 'Home to multiple elite traditional',
        home5: 'and international schools',
        home6: 'Terra is located in the renowned School Net 34 in the Kowloon district, an area with numerous traditional elite schools highly favored by parents',
        home7: "For secondary schools, the network belongs to the Kowloon City District, with well-known institutions including Diocesan Boys' School, La Salle College, Maryknoll Fathers' School, and King George V School, among others, which are highly competitive and unparalleled in advantages",
        home8: 'Overlooking breathtaking mountains',
        home9: 'Every view, captured',
        home10: 'Beauty beyond words',
        home11: 'By top architects',
        home12: 'Expertly crafted',
        home13: 'Landmark properties',
        home14: 'Floor plans',
        home15: 'Residential',
        home16: 'Popular schools within the network include St. Rose School and Heep Yunn School Primary Section, etc.;',
        home17: 'INFORMATION TO PURCHASERS AND DISCLAIMER',
        home18: 'This notice applies if this website www.terraluxuryhk.com is regarded as advertising.',
        home19: 'Name of the development: "Terra" of the development project at 296 Prince Edward Road West (the "Development")',
        home20: 'Note: The development project at No. 296, Prince Edward Road West, is built in the remaining section of Kowloon Inland Lot No. 2097 ("the Lot", the Chinese name is "隆敍" and the English name is "TERRA".',
        home21: 'District: Ho Man Tin',
        home22: 'The street name and house number where TERRA is located: No. 296 Prince Edward Road West',
        home23: 'The website address for the implementation of the First-hand Residential Properties Sales Ordinance by the Vendor: www.terraluxuryhk.com',
        home24: "The photographs, images, drawings or sketches contained in this advertising/promotional material represent an artist's impression of the development concerned. The photographs, images, drawings or sketches are not drawn to scale and/or may have been computer enhanced. Prospective buyers should refer to the sales brochure for details of the development. The vendor also recommends that prospective buyers conduct on-site inspections of the development site to gain a better understanding of the development site, its surrounding environment and nearby public facilities.",
        home25: 'Vendor: Top Gear Investment Company Limited',
        home26: 'Holding company of the Vendor (owner): Pure Wide Limited',
        home27: 'The firm or corporation in which the authorized person of the development is the operator, director or employee in his professional capacity: CYS Associates (HK) Ltd.',
        home28: 'Authorized Person for the Development Mr. KAM Thomas Hing Pong',
        home29: 'Building Contractor for the Development: Hien Lee Engineering Co., Ltd',
        home30: 'The firm of solicitors acting for the owner in relation to the sale of residential properties in the Development: Lo, Wong & Tsui Solicitors & Notaries',
        home31: 'Any authorized institution that has made a loan, or has undertaken to provide finance, for the construction of the Development: Bank of China (Hong Kong) Limited (Released on 5th December 2023)',
        home32: 'The Vendor reserves the right to change the architectural plans and other plans from time to time. The design of Terra shall be subject to the final plans approved by the relevant government departments.',
        home33: 'This advertisement is published by the person so engaged with the consent of the owner.',
        home34: 'The vendor advises prospective buyers to refer to the relevant sales brochure for information on Terra. Please refer to the sales brochure for details.',
        home35: 'This advertisement/promotional material is for reference only and does not constitute and shall not be construed as the Vendor making any contractual terms, offers, representations, undertakings or guarantees, whether express or implied, with respect to Terra or any part thereof. ',
        home36: 'Date of Last Updated: {date}',
    },
    navigator: {
        navigator1: 'Sales Brochure',
        navigator2: 'Price List',
        navigator3: 'Record of Transactions',
        navigator4: 'Sales Arrangements',
        navigator5: 'DMC',
        navigator6: 'Aerial Photographs',
        navigator7: 'Tender',
    },
    footer: {
        footer1: 'Address: 296 Prince Edward Road West, Ho Man Tin',
        footer2: 'Lot Number: Kowloon Inland Lot No. 2097',
        footer3: "Except as specifically mentioned, the photos and images on this webpage were not taken at or made based on any phase of the development project or any part thereof, and do not reflect the appearance, landscape, design, fitting, decoration materials, equipment, facilities, services, and usage of any phase of the development project or any part thereof, and may be unrelated to the development project or any part thereof. The related photos and images do not constitute and should not be interpreted as making any offer, representation, promise, or guarantee, whether express or implied.",
    },
    language: {
        language: 'English',
        zh: 'zh-CN',
        en: 'en',
        tw: 'zh-TW',
        zhName: '简体中文',
        zhTwName: '繁體中文',
        enName: 'English',
    },
}